@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Hanken Grotesk';
  src: url('./fonts/HankenGrotesk-VariableFont_wght.ttf');
}

@layer base {
  html,
  body {
    font-family: Hanken Grotesk, system-ui, sans-serif !important;
  }
  body:after {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0.4;
    z-index: -1;
    background-color: #f7f7f8;
    background: url('./icons/blurred-logo.png') #f7f7f8 no-repeat 100% 100%;
    background-size: cover;
  }
}
